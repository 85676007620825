$primary-1100: #5cb4d6;
$primary-1200: #52a0be;
$primary-1300: #488ca6;
$primary-1400: #3d788e;
$primary-1500: #336477;
$primary-1600: #29505f;
$primary-1700: #1e3c47;
$primary-1800: #14282f;
$primary-1900: #0a1417;
$gray-200: #EEEEEE ;

$primary-100: #eff9fd;
$primary-200: #e0f4fb;
$primary-300: #d1eef9;
$primary-400: #c2e9f8;
$primary-500: #b3e3f6;
$primary-600: #a3def4;
$primary-700: #94d8f3;
$primary-800: #85d3f1;
$primary-900: #76cdef;


.file-upload-component {
  .file-upload-input {
    display: none;;
  }


  .image-preview {
    max-width: 100%;
    max-height: 100px;
    margin: 8px auto;
    display: block;
  }

  .file-preview {
    color: $primary-1500;
    text-overflow: ellipsis;
    width: 400px;
    overflow: hidden;
    margin: 16px auto;
    .las {
      font-size: 64px;
    }
  }

  .file-upload-label {
    display: block;
    text-align: center;
    align-content: center;
    justify-content: center;
    border-radius: 8px;
    background: $gray-200;
    color: $primary-1300;
    border: 2px dotted gray;
    padding: 48px 0;
    cursor: wait;
    transition: all 0.1s;

    &.enabled {
      cursor: pointer;
      background: $primary-200;
      color: $primary-1300;
      border: 2px dotted $primary-1300;
      &:active{
        transform: scale(0.98);

      }

      &:focus{
        transform: scale(0.98);
      }
    }



  }
}
