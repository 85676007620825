$hrc-green: #12CBC4 !default;
$hrc-blue: #67c8ee !default;
$hrc-red: #EA2027 !default;
$hrc-yellow: #FFC312 !default;
$hrc-orange: #F79F1F !default;

$hrc-primary: #093c71 !default;
$hrc-secondary: #fdd101 !default;

$hrc-gray-50: #FAFAFA !default;
$hrc-gray-100: #F5F5F5 !default;
$hrc-gray-200: #EEEEEE !default;
$hrc-gray-300: #E0E0E0 !default;
$hrc-gray-400: #BDBDBD !default;
$hrc-gray-500: #9E9E9E !default;
$hrc-gray-600: #757575 !default;
$hrc-gray-700: #616161 !default;
$hrc-gray-800: #424242 !default;
$hrc-gray-900: #212121 !default;

.select-component {
  position: relative;
  .select-label {
    background: #fff;
    display: inline-block !important;
    margin-left: 10px;
    padding: 0 6px !important;
    position: absolute;
    top: -5px;

  }
}

.hrc-select {
  .hrc-select__value-container--is-multi.hrc-select__value-container--has-value {
    padding-top: 6px;
  }
  .hrc-select__single-value {
    font-size: 13px;
  }
  .hrc-select__placeholder {
    font-size: 12px;
  }
  .hrc-select__control {

    border-color: $hrc-gray-400;
    min-height: 52.7px;
    &.hrc-select__control--is-focused {
      box-shadow: none;
      border-color: $hrc-primary;
    }
    .hrc-select__value-container{
      .hrc-select__placeholder{
      }
      .hrc-select__multi-value {
      }
    }
    .hrc-select__indicators{
      .hrc-select__indicator-separator{
      }
    }
  }

  .hrc-select__menu-portal {
    z-index: 2;
  }

  .hrc-select__menu{
    box-shadow: 0 0 1.5rem 6px rgba(58, 59, 69, 0.15);
    border: 1px solid $hrc-gray-300;
    margin-top: 0;
    .hrc-select__option {
      font-size: 13px;
      padding: 4px 13px;
      &.hrc-select__option--is-focused{
        background-color: $hrc-gray-200;
        color: #000;
      }
      &.hrc-select__option--is-selected {
        background-color: $hrc-primary;
        color: #fff;
      }
    }
  }
}

.status-circle {
  display: inline-block;
  width: 0.6em;
  height: 0.6em;
  border-radius: 0.3em;
  opacity: 0.6;
  background-color: $hrc-gray-400;
}

.loader-row{
  display: block;
  overflow: hidden;

  @keyframes placeHolderShimmer{
    0%{
      background-position: -1200px 0
    }
    100%{
      background-position: 1200px 0
    }
  }

  .animated-background {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #fdfdfd;
    background: linear-gradient(to right, #F2F3F6 8%, #e2e2e2 18%, #F2F3F6 33%);
    background-size: 2400px 104px;
    height: 100%;
    position: relative;
  }

  .loader-item{
    float: left;
    height: 100%;
    border-right: 4px solid #fff;
    &:nth-last-child(2){
      border-right: 0;
    }
  }
}

