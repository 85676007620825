@import "colors";

:global {

  .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows {
    margin-bottom: 0;
  }

  .cursor-pointer {
    cursor: pointer;
    &:hover {
      color: $hrc-primary;
    }
  }

  .space{
    clear: both;
    height: 8px;
  }

  .space-sm{
    clear: both;
    height: 4px;
  }

  .space-xs{
    clear: both;
    height: 2px;
  }

  .space-md{
    clear: both;
    height: 12px;
  }

  .space-lg{
    clear: both;
    height: 16px;
  }

  .space-xl{
    clear: both;
    height: 22px;
  }

  .page-title {
    font-size: 1.4rem;
    line-height: 1.2222222222222223;
    letter-spacing: 0.2px;
    font-weight: 800;
    margin: 10px 0;
    color: #0A1929;
  }

  .hays-md-modal {

    .MuiDialog-container {
      align-items: flex-start;
      margin-top: 10%;
    }

    .MuiPaper-root {
      min-width: 500px;
    }

    .modal-form {
      padding-top: 10px;
      .MuiFormControl-root {
        width: 100%;
      }
    }
  }

}
