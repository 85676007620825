@import "~modules/root/scss/_colors.scss";

.cities-update-component {
  .city-item-view {
    .city-item {
      padding: 6px 12px;
      border-radius: 4px;
      border: 1px solid $hrc-gray-300;
      margin-bottom: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 13px;

      &.level-1 {
        background-color: $hrc-gray-200;
      }
      &.level-2 {
        background-color: $hrc-gray-100;
      }
      &.level-3 {
        background-color: $hrc-gray-50;
      }

      .btn {
        margin-left: auto;
      }
    }

    .level-label {
      margin-left: 16px;
      color: $hrc-gray-500;
      font-size: 12px;
    }

    .city-name {
      cursor: pointer;
      &:hover {
        color: $hrc-primary;
      }
    }

  }
}
