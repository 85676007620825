@use "scss/colors";
@import "scss/fonts";
@import "scss/base";



html {
  background-color: #ffffff;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}
