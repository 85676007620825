$hrc-green: #12CBC4 !default;
$hrc-blue: #67c8ee !default;
$hrc-red: #EA2027 !default;
$hrc-yellow: #FFC312 !default;
$hrc-orange: #F79F1F !default;

$hrc-primary: #67c8ee !default;
$hrc-secondary: #a57ab6 !default;

$hrc-gray-50: #FAFAFA !default;
$hrc-gray-100: #F5F5F5 !default;
$hrc-gray-200: #EEEEEE !default;
$hrc-gray-300: #E0E0E0 !default;
$hrc-gray-400: #BDBDBD !default;
$hrc-gray-500: #9E9E9E !default;
$hrc-gray-600: #757575 !default;
$hrc-gray-700: #616161 !default;
$hrc-gray-800: #424242 !default;
$hrc-gray-900: #212121 !default;

.notification__item {
  box-shadow: 2px 4px 15px 0 rgba(0, 0, 0, 0.24);
  .notification__message {
    color: $hrc-gray-700;
  }
  .notification__close {
    background: inherit;
    &:after {
      color: $hrc-gray-400;
      font-size: 14px;
    }
  }
}

.notification__item--success {
  background: #fff;
  border-left: 6px solid $hrc-green;
}

.notification__item--danger {
  background: #fff;
  border-left: 6px solid $hrc-red;
}

.notification__item--warning {
  background: #fff;
  border-left: 6px solid $hrc-orange;
}

.notification__item--info {
  background: #fff;
  border-left: 6px solid $hrc-blue;
}

.notification__item--default {
  background: #fff;
  border-left: 6px solid $hrc-gray-700;
}
