$hrc-green: #12CBC4 !default;
$hrc-blue: #67c8ee !default;
$hrc-red: #EA2027 !default;
$hrc-yellow: #FFC312 !default;
$hrc-orange: #F79F1F !default;

$hrc-primary: #093c71 !default;
$hrc-secondary: #fdd101 !default;

$hrc-gray-50: #FAFAFA !default;
$hrc-gray-100: #F5F5F5 !default;
$hrc-gray-200: #EEEEEE !default;
$hrc-gray-300: #E0E0E0 !default;
$hrc-gray-400: #BDBDBD !default;
$hrc-gray-500: #9E9E9E !default;
$hrc-gray-600: #757575 !default;
$hrc-gray-700: #616161 !default;
$hrc-gray-800: #424242 !default;
$hrc-gray-900: #212121 !default;
