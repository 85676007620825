.contents-container{
  .mobile-screen {
    width: 360px;
    height: 700px;
    background: #e7ebef;
    border: 1px solid #e7ebef;
    border-radius: 8px;
    overflow: hidden;
    padding: 8px;
    .content-view {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
    textarea {
      width: 100%;
      height: 100%;
    }
  }
}
